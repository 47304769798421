@import "../../../styles/vars";

.capture-image {
  .canvas {
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s $CubicEaseInOut, visibility .4s $CubicEaseInOut;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .help-button {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 100;

    .help-button-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba($color: #2A2C2C, $alpha: 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .help-content {
      position: absolute;
      right: -10px;
      top: 48px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-20px);
      background-color: #000;
      color: #fff;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      column-gap: 12px;
      border-radius: 4px;
      transition: all 0.4s $QuadEaseInOut;
      width: 240px;
      padding: 16px;

      &:before {
        content: "";
        position: absolute;
        right: 20px;
        top: -6px;
        width: 12px;
        height: 12px;
        background-color: #000;
        transform: rotate(45deg);
      }

      h3 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        margin: 0;
      }
    }

    &:hover {
      .help-content.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  button {
    position: fixed;
    bottom: 30px;
    left: calc( 50% - 28px );
    width: 56px;
    height: 56px;
    background-color: #ED8002;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &:active {
      transform: scale(0.95);
    }

    &.spin {
      img {
        animation: spin 1.5s $CubicEaseInOut infinite;
      }
    }
  }

  .toast {
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%) translateY(30px);
    width: 310px;
    height: 62px;
    background-color: rgba($color: #2A2C2C, $alpha: 0.8);
    color: #fff;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .3s $CubicEaseInOut, transform .3s $QuadEaseInOut;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    &.show {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }

    p {
      font-size: 14px;
      line-height: 16px;

      span {
        color: $orange;
				font-weight: 700;
				display: block;
				margin-top: 4px;
      }

      strong {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .scan {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    width: 310px;
    height: 310px;
    z-index: 99;

    .left {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
        height: 32px;
        border-left: #fff solid 2px;
        border-top: #fff solid 2px;
        border-top-left-radius: 8px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 32px;
        height: 32px;
        border-left: #fff solid 2px;
        border-bottom: #fff solid 2px;
        border-bottom-left-radius: 8px;
      }
    }

    .right {
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        border-right: #fff solid 2px;
        border-top: #fff solid 2px;
        border-top-right-radius: 8px;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 32px;
        height: 32px;
        border-right: #fff solid 2px;
        border-bottom: #fff solid 2px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .location {
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 999;
    background-color: rgba($color: #2A2C2C, $alpha: 0.8);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
  
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.target-checked {
        display: none;
      }
    }

    &.in-target {
      svg {
        &.target-checked {
          display: block;
        }

        path {
          fill: #8f8;
        }
      }
    }
  }
  
  .camera {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: auto;
    height: 100vh;
  }
  
  .capture-holder {
    position: absolute;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    @keyframes beam {
      50% {
        opacity: 0;
      }
    }
    
    @keyframes scanning {
      50% {
        transform: translateY(330px);
      }
    }

    .laser {
      width: 150%;
      margin-left: -25%;
      background-color: #8f8;
      height: 1px;
      position: absolute;
      top: 90px;
      z-index: 2;
      box-shadow: 0 0 4px #0f0;
      animation: scanning 4s infinite;
    }
  
    .diode {
      animation: beam .01s infinite;
    }
  
    ul {
      position: absolute;
      list-style: none;
      padding: 0;
      margin: 0;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
  
      .bounding {
        position: absolute;
        border: #0f0 solid 2px;
  
        span {
          background-color: #0f0;
          color: #fff;
          position: absolute;
          left: -2px;
          top: -29px;
          font-size: 15px;
          padding: 5px; 
        }
      }
    }
  
    .captured {
      height: 100%;
      position: relative;
    }
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    width: 14px;
    height: 14px;
    z-index: 12;
    cursor: pointer;
  
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}