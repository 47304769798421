@import "../../../styles/vars";

.maintenance {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background-image: url(../../../img/background-mobile.jpg);
    background-position: center;
    background-size: cover;
    color: #fff;

    @include XGA {
        background-image: url(../../../img/background-desktop.jpg);
    }

    .maintenance-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;

        img {
            height: 64px;

            @include XGA {
                height: 94px;
            }
        }

        .service-img {
            display: block;
            margin: 40px auto;
            height: 64px;

            @include XGA {
                height: 120px;
            }
        }

        h2 {
            font-size: 32px;
        }

        p {
            font-size: 15px;
        }

        h1, h2, strong {
            font-weight: 600;
        }

        .social {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;

            li {
                margin: 0 12px;

                img {
                    height: 48px;
                }
            }
        }
    }
}