@import "../../../styles/vars";

.welcome-page {
  .bg-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: top center;
    opacity: 0;
    transition: opacity .4s $QuadEaseInOut;

    &.active {
      opacity: 1;
    }
  }

  &.camera-permission {
    position: relative;
    color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    height: 100vh;

    h3 {
      font-size: 24px;
      font-weight: 800;
      margin: 0;
      margin-bottom: 36px;
    }

    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      margin: 0;
    }

    .button {
      position: absolute;
      bottom: 90px;
      width: calc( 100% - 60px );
    }
  }
}