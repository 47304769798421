@import "../../../styles/vars";

.button {
    display: inline-block;
    padding: 11px 40px 11px 40px;
    font-size: 14px;
    border-radius: 24px;
    font-weight: 900;
    text-decoration: none;
    border: none;
    cursor: pointer;
    user-select: none;
    text-align: center;

    &.disabled {
        pointer-events: none;
        cursor: initial;
        user-select: none;
    }

    &.orange {
        color: white;
        background-color: $lightorange;
        border: 1px solid transparent;

        &:hover {
            background: $orange;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
        }

        &:active {
            background: $darkorange;
        }

        &.disabled {
            background: #C6C6C5;
        }
    }

    &.white {
        color: $lightorange;
        background-color: white;
        border: 1px solid $lightorange;
        padding: 12px 41px 12px 41px;

        &:hover {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
            border: 2px solid $orange;
            color: $orange;
            padding: 11px 40px 11px 40px;
        }

        &:active {
            color: $darkorange;
            border: 2px solid $darkorange;
            outline: none;
            padding: 11px 40px 11px 40px;
        }

        &.disabled {
            border: 2px solid #C6C6C5;
            color: #C6C6C5;
            padding: 11px 40px 11px 40px;
        }
    }

    &.code {
        background-color: $lightorange;
        color: #fff;
        letter-spacing: .08em;
        width: fit-content;

        &:hover {
            background: $orange;
        }

        &:active {
            background: $darkorange;
        }

        &.disabled {
            background: #C6C6C5;
        }
    }

    &.menu {
        padding: 13px 25px 9px 25px;
        font-weight: 700;

        &:hover {
            padding: 12px 24px 8px 24px;
        }

        &:active {
            padding: 12px 24px 8px 24px;
        }

        &.disabled {
            padding: 12px 24px 8px 24px;
        }
    }

    &.menu-small {
        padding: 6px 14px 5px;
        font-size: 11px;
        line-height: 11px;
        // text-align: center;
        font-weight: 800;

        &:hover {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
            border: 1px solid $orange;
            color: $orange;
            padding: 6px 14px 5px;
        }

        &:active {
            color: $darkorange;
            border: 1px solid $darkorange;
            padding: 6px 14px 5px;
        }

        &.disabled {
            padding: 6px 14px 5px;
            border: 1px solid #C6C6C5;
            color: #C6C6C5;
        }
    }

    &.referral {
        color: white;
        background-color: $lightorange;
        border: 1px solid transparent;
        font-weight: 900;
    }
}