@import "../../../styles/vars";

.map-page {
  .map {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    height: 100vh;
  }

  .leaflet-marker-icon.cluster {
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50%;
    line-height: 30px;
    font-size: 12px;
    background-color: rgba($color: #2A2C2C, $alpha: 0.8);
    text-align: center;
    font-weight: 700;
  }

  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      color: #fff;
      background-color: rgba($color: #2A2C2C, $alpha: 0.8);
      border-radius: 12px;
    }

    .leaflet-popup-close-button {
      filter: brightness(0) invert(1);
    }

    .leaflet-popup-tip {
      border-top: 10px solid rgba($color: #2A2C2C, $alpha: 0.8);
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .bottom-modal {
    height: 430px;
    padding: 24px;

    .search-input {
      position: relative;
      margin-bottom: 12px;

      &::before {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
        display: block;
        position: absolute;
        width: 15px;
        z-index: 3;
        height: 15px;
        font-size: 20px;
        top: 3px;
        left: 10px;
        line-height: 32px;
        opacity: 1;
      }

      input {
        padding: 8px 32px 6px 32px;
        width: 100%;
        outline: none;
        background-color: rgba($color: #fff, $alpha: .7);
        border: none;
        border-radius: 12px;
      }

      span.clear {
        position: absolute;
        right: 10px;
        top: 8px;
        width: 15px;
        height: 15px;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          width: 12px;
          height: 2px;
          background-color: #000;
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          width: 12px;
          height: 2px;
          background-color: #000;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }

    .location-list {
      height: 250px;
    }
  }

  button {
    position: fixed;
    bottom: 30px;
    left: calc( 50% - 28px );
    width: 56px;
    height: 56px;
    background-color: rgba($color: #2A2C2C, $alpha: 0.8);
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    &:active {
      transform: scale(0.95);
    }
  }

  .location-list {
    height: 200px;
    width: 100%;
    overflow-y: auto;

    li {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: flex-start;

      img {
        margin-right: 8px;
      }

      div {
        text-align: left;
        padding-top: 12px;

        h5, p {
          margin: 0;
          line-height: 20px;
        }

        h5 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}