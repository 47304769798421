// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

:root {
	color-scheme: light;
}

body,
html {
	margin: 0;
}

body {
	font-family: $main;
	background-color: #000;
}

.app {
	position: relative;
    height: 100vh;
    overflow-x: hidden;
}

ul {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
}

p {
	line-height: 1.4;
}

.container {
	position: relative;
	width: 85%;
	max-width: 1280px;
	margin: 0 auto;
}

.back-button {
	position: fixed;
	left: 30px;
	top: 30px;
	z-index: 100;
	background-color: rgba($color: #2A2C2C, $alpha: 0.8);
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: none;
	cursor: pointer;

	&::before {
		content: "";
		position: absolute;
		left: 50%;
		top: 40%;
		transform: translate(-50%, -50%) rotate(-45deg);
		width: 10px;
		height: 2px;
		background-color: #fff;
		transition: all .2s $QuadEaseInOut;
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 60%;
		transform: translate(-50%, -50%) rotate(45deg);
		width: 10px;
		height: 2px;
		background-color: #fff;
		transition: all .2s $QuadEaseInOut;
	}

	&.az {
		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 16px;
			height: 2px;
			background-color: #fff;
		}
	
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 16px;
			height: 2px;
			background-color: #fff;
		}
	}
}

.buttons {
	.button {
		margin: 0 8px;
	}

	.button.orange {
		color: #fff;
	}
}

.bottom-modal {
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(40px);
    opacity: 0;
    bottom: 30px;
    width: calc( 100% - 60px);
    height: 330px;
    max-width: 400px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000 80%), url(../app/pages/Welcome/img/bottom-modal-bg.svg);
    background-size: 100% 100%, 104% 104%;
	background-position: center center;
    // background-image: url(../app/pages/Welcome/img/bottom-modal-bg.svg);
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
    border-radius: 15px;
    padding: 32px;
    transition: opacity .4s $CubicEaseInOut, transform .4s $QuadEaseInOut;
	z-index: 9999;
	overflow: hidden;

	.button {
		width: calc( 100% - 48px );
		border-radius: 24px;
		left: 24px;
		bottom: 24px !important;
		color: #fff;
		height: 40px;
	}
	
	.content {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;

		p {
			span {
				color: $orange;
				font-weight: 700;
				display: block;
				margin-top: 4px;
			}
		}
	}

	.code {
		height: 40px;
		border: 3px solid #E63B11;
		border-radius: 24px;
		line-height: 40px;
		font-size: 15px;
		padding: 0 24px;
		margin: 16px 0;
	}

    &.animate {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }

    &.intro {
      height: 280px;
    }

	&.full {
		height: 200px;
		background-image: none;
		background-color: rgba($color: #2A2C2C, $alpha: .95);
	}

    .dots {
      position: relative;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: center;

      li {
        background-color: #868686;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 4px;
        transition: all .3s $CubicEaseInOut;

        &.active {
          background-color: #E5451F;
          width: 24px;
          border-radius: 4px;
        }
      }
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
      margin: 0;
      margin-top: 16px;
	  text-transform: uppercase;
	  font-weight: 900;
	  margin-top: 16px;
	  margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
	  font-weight: 700;
      margin-top: 0;

	  a {
		color: #fff;
	  }
    }

    .icon {
      margin-top: 24px;
    }

    button {
      position: absolute;
      bottom: 0 !important;
      left: calc( 50% - 28px );
      width: 56px;
      height: 56px;
      background-color: #ED8002;
      border-radius: 50%;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

	  @media (prefers-color-scheme: dark) {
		background-color: #ED8002;
	  }

      &:active {
        transform: scale(0.95);
      }
    }
  }

  .camera {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: auto;
    height: 100vh;
  }

	.code-btn {
		z-index: 99999;
		position: fixed;
		bottom: 80px;
		left: 50%;
		width: 200px;
		color: #fff;
		text-align: center;
		height: 40px;
		background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
		border-radius: 24px;
		line-height: 40px;
		font-size: 15px;
		padding: 0 24px;
		margin-left: -100px;
	}


.coords-stats {
	position: fixed;
	right: 30px;
	top: 70px;
	width: 180px;
	background-color: rgba($color: #2A2C2C, $alpha: 0.8);
	color: #fff;
	border-radius: 15px;
	z-index: 9999;
	padding: 20px;

	& div.valid {
		color: #8f8;
	}

	& li.valid {
		color: #8f8;
	}

	ul {
		li {
			&:nth-child(odd) {
				background-color: rgba($color: #000000, $alpha: 0.2);
			}
		}
	}

	p {
		margin: 0 4px;
		white-space: nowrap;
		font-size: 10px;

		&.flex {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}

	.close-debug {
		position: absolute;
		right: 0;
		top: -40px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: rgba($color: #2A2C2C, $alpha: 0.8);

		&::before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 14px;
		height: 2px;
		background-color: #fff;
		transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 14px;
		height: 2px;
		background-color: #fff;
		transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}