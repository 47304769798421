// ----------------------------
//	TOOLS & UTILS
// ----------------------------

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// ----------------------------
//	COLORS
// ----------------------------

$darkgray: #2A2C2C;
$violet: #754fb2;
$green: #0eb18a;
$black: #161616;

$darkorange: #E5451F;
$orange: #e86215;
$lightorange: #ED8002;
$yellow: #F5AA04;

// ----------------------------
//	EASING
// ----------------------------

$CubicEaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$CubicEaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$CubicEaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$QuadEaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$QuadEaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$QuadEaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);

// ----------------------------
//	Break Points
// ----------------------------

$screen-vHD: 720px;
$screen-XGA: 1020px;
$screen-HD: 1280px;
$screen-UXGA: 1590px;
$screen-FHD: 1910px;

@mixin vHD {
  @media screen and (min-width: $screen-vHD) {
    @content;
  }
}

@mixin XGA {
  @media screen and (min-width: $screen-XGA) {
    @content;
  }
}

@mixin HD {
  @media screen and (min-width: $screen-HD) {
    @content;
  }
}

@mixin UXGA {
  @media screen and (min-width: $screen-UXGA) {
    @content;
  }
}

@mixin FHD {
  @media screen and (min-width: $screen-FHD) {
    @content;
  }
}
