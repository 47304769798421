@import "../../../styles/vars";

.not-found {
    min-height: 100vh;

    @include XGA {
        height: 100%;
    }

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include XGA {
            justify-content: initial;
        }

        h1 {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            max-width: 230px;
            margin: 0;

            @include XGA {
                margin-top: 80px;
                font-size: 32px;
                line-height: 40px;
                max-width: initial;
            }
        }

        .image {
            margin-top: 6vh;
            margin-bottom: 6vh;
            width: 26.5vw;

            @include XGA {
                margin-top: 64px;
                margin-bottom: 64px;
                width: 14vw;
            }
        }

        .text {
            & > * {
                margin: 0;
                max-width: 325px;

                @include XGA {
                    max-width: 480px;
                }
            }

            h2 {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                margin-bottom: 8px;

                @include XGA {
                    margin-bottom: 16px;
                }
            }

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #868686;
            }
        }

        .button-wrapper {
            margin-top: 6vh;

            @include XGA {
                margin-top: 40px;
                margin-bottom: 80px;
            }
        }
    }
}