@import "../../../styles/vars";

.switch {
    position: fixed;
    top: 30px;
    left: calc( 50% - 60px );
    width: 120px;
    height: 32px;
    border-radius: 16px;
    background-color: rgba($color: #2A2C2C, $alpha: 0.8);
    z-index: 100;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: center;
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity .4s $CubicEaseInOut, transform .4s $QuadEaseInOut;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 32px;
        width: 50%;
        border-radius: 16px;
        background-color: #ED8002;
        transition: left .3s $QuadEaseInOut;
    }

    &.mapa {
        &::before {
            left: 50%;
        }
    }

    li {
        position: relative;
        min-width: 50%;
        max-width: 50%;
        width: 50%;
        text-align: center;
        
        a {
            display: block;
            font-size: 10px;
            color: #fff;
            font-weight: 700;
            text-decoration: none;
            line-height: 32px;
        }
    }
}