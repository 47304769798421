.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 0.2s $QuadEaseOut;
  transition-delay: 0.2s;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.2s $QuadEaseOut;
}

.news-img-enter {
  visibility: hidden;
}

.news-img-enter-active {
  visibility: visible;
  transition: opacity 0.7s $QuadEaseOut;
  transition-delay: 1s;
}

.news-img-exit {
  opacity: 1;
}

.news-img-exit-active {
  opacity: 0;
  transition: opacity 0.7s $QuadEaseOut;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
	transform: rotate(180deg) scale(0.8)
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes op {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}


@keyframes arrow {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 $lightorange;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-black {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.45);
	}

	70% {
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-oragne-transform-off {
	0% {
		box-shadow: 0 0 0 0 $lightorange;
	}

	70% {
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.96);
		box-shadow: 0 0 0 0 $darkorange;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.96);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}